<template>
<div>
  <template>
    <div id="breadcrumb-alignment" class="mb-1">
      <div class="d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb
          :items="breadCrumb()"
        />
      </div>
    </div>
  </template>

  <b-row class="match-height">
    <b-col lg="12" md="12">   
      <b-card title="Edit Item">

        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
          <div class="alert-body">
            {{error_message}}
          </div>
        </b-alert>
        
        <b-form @submit="formSubmit">
          
          <b-row>
            
            <b-col md="12">

              <b-row>

                <b-col md="6">                
                  <b-form-group label="Project Site" class="required">
                    <b-form-select v-model="form.site">
                      <b-form-select-option value="" disabled>Select</b-form-select-option>
                      <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label="Item Name" class="required">
                    <b-form-input autocomplete="off" placeholder="3 to 100 characters" v-model="form.item"/>
                  </b-form-group>
                </b-col>
                
              </b-row>            
              
              <b-row>

                <b-col>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="warning" class="mr-1">
                    Submit
                  </b-button>
                  <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="$router.push({ name:'flash-item', params: { site_id: form.site } })">
                    Cancel
                  </b-button>
                </b-col>

              </b-row>

            </b-col>
          </b-row>

        </b-form>
      </b-card>
    </b-col>
  </b-row>

</div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BBreadcrumb
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'


export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BAvatar,
    BTable,
    BModal,
    VueCropper,
    BFormRadio,
    BFormTimepicker,
    VueTimepicker,
    BBreadcrumb
  },
  directives: {
    Ripple,
  },
  data() {
    return {      
      error_message:null,
      showDismissibleAlert:false,

      sites:[],

      form :{
        site : '',
        item:'',
        id:null
      },
      
    }
  },
  methods : {
    formSubmit(e){
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
           data:{
             items:this.form
           },
           api: '/api/update-flash-item'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Record Updated Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    this.$router.push({ name:'flash-item', params: { site_id: this.form.site } })
                });
                
            }
        });
      
    },

    allSites(){
      return this.$store.dispatch(POST_API, {
           data:{
              role:this.$store.getters.currentUser.role,
              om_sites:this.$store.getters.currentUser.om_sites,
           },
           api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.sites = data;
                return this.sites;
            }
        });
    },
    itemDetail(){
      return this.$store.dispatch(POST_API, {
           data:{
              id:this.$route.params.id,              
           },
           api: '/api/flash-item-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.form  = {
                  site : data.site,
                  item : data.item,
                  id   : data._id
                };

                return this.form;
            }
        });
    },

    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:{name:'custom-forms'},
        text: 'Custom Forms',
      },{
        to:{name:'flash-time'},
        text:'Flash Times',             
      },{
        to:{name:'flash-item',params: { site_id: this.$route.params.site_id }},
        text: 'Items',        
      },{
        to:null,
        text: 'Edit Items',
        active:true
      }];

      return item;
    },
    

  },
  mounted(){
    this.allSites();
    this.itemDetail();
  }
}
</script>
